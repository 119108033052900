<template>
  <v-dialog v-model="value" @click:outside="close" scrollable max-width="90vw" >
    <v-container fluid class="pa-0">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ item.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="close" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card>
      <v-card-subtitle class="artwork-title">
        This work is available in the following product ranges
      </v-card-subtitle>
      <v-row>
        <v-col :key="$index" v-for="(product, $index) in item.products" xl="2" lg="3" sm="4" cols="12" class="d-flex flex-column">
          <v-card class="mx-2 my-2 d-flex flex-column flex-grow-1">
              <v-card-title>
                {{ product[0].category }}
              </v-card-title>
            <v-card-text class="d-flex flex-column">
              <!-- <v-img v-for="(product, index) of item.products" :key="index" :src="`${imageUrl}/100px/${product.artworkImage}`" ></v-img> -->
              <v-img :src="`${imageUrl}/300px/${product[0].productImage}`">
              </v-img>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
// import ProductApi from '@/api/product'
// import ImageThumbnail from '@/components/ImageThumbnail.vue'
// import VueBarcode from 'vue-barcode'
export default {
  components: {
    // ImageThumbnail,
    // VueBarcode
  },
  props: {
    value: Boolean,
    item: Object
  },
  data () {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      currentItem: this.item,
      currentVariant: 0,
      thumbCols: 1,
      imgCols: 6,
      dataCols: 5,
      mainImage: 0,
      barcodeFormat: 'EAN-13'
    }
  },
  computed: mapState({
  }),
  methods: {
    close () {
      this.$emit('close', this.item)
    }
  }
}
</script>
<style scoped>
.artwork-title {
  font-size: 1.1rem
}
</style>
